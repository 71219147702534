<template>
   <Reporte :signable="true" :init="$router.currentRoute.query.t"/>
</template>

<script>
import Reporte from "../components/Reporte/Reporte.vue"

export default {
   components:{
      Reporte
   }
};
</script>
