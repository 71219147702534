<template>
   <v-expand-transition>
      <v-alert v-model="show" dismissible v-show="show" :type="type">
         <div>
            <div v-if="!is_description">
               {{ message }}
            </div>
            <div v-else>
               <div
                  class="my-btn"
                  @click="
                     expand = !expand;
                     cancel_close = true;
                  "
               >
                  {{ message }}
               </div>
               <v-expand-transition>
                  <json-viewer class="description" :value="description" v-show="expand"></json-viewer>
               </v-expand-transition>
            </div>
         </div>
      </v-alert>
   </v-expand-transition>
</template>

<script>
export default {
   name: "Notification",
   props: {
      type: String,
      message: String,
      duration: {
         type: Number,
         default: 2000,
      },
   },
   data: function () {
      return {
         description: "",
         is_description: false,
         expand: false,
         cancel_close: false,
         show: false,
         timeout: null,
      };
   },
   methods: {
      async Show() {
         this.show = true;
         this.description = "";
         this.timeout = await setTimeout(() => {
            this.close();
         }, this.duration);
         return true;
      },
      async ShowPersistent(description) {
         this.show = true;
         this.is_description = true;
         if (description.response) {
            this.description = JSON.parse(JSON.stringify(description.response));
         } else {
            this.description = JSON.parse(JSON.stringify(description));
         }
         this.timeout = await setTimeout(() => {
            if (!this.cancel_close) {
               this.close();
            }
         }, 5000);
      },
      close() {
         this.expand = false;
         this.description = false;
         this.cancel_close = false;
         this.show = false;
         clearTimeout(this.timeout);
      },
   },
   watch: {
      show: function () {
         if (this.show == false) {
            this.close();
         }
      },
   },
};
</script>

<style scoped>
.v-alert {
   position: fixed;
   left: 50%;
   bottom: 50px;
   transform: translate(-50%, 0);
   max-width: 400px;
   width: max-content;
   z-index: 999;
}

.my-btn {
   text-decoration: none;
   display: inline-block;
   cursor: default;
   transition: all ease-in-out 200ms;
}
.my-btn:hover {
   text-decoration: underline;
   cursor: pointer;
   transition: all ease-in-out 200ms;
}

.description {
   max-height: 300px;
   max-width: 500px;
   overflow: scroll;
}
</style>
